import React from 'react'

import Layout from '../components/layout'

export default () => (
  <Layout>
    <h3>
      Ops!
    </h3>
    <p>
      Pagina non trovata
    </p>

  </Layout>
)
